import React from "react"

import Layout from "../components/layout"

export default () => {
  return (
    <>
      <Layout>
        <section id="hero" className="hero-404">
          <div className="row">
            <h1>Page not found</h1>
          </div>
        </section>
      </Layout>
    </>
  )
}
